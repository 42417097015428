<template>
  <div class="px-5 mt-5">
    <!-- Header -->
    <div class="grid gap-2 grid-cols-1 xl:grid-cols-3">
      <div class="flex flex-row items-center w-full">
        <label class="mr-2 text-sm font-semibold text-gray-600  w-1/12"
          >Groupe sanguin:
        </label>
        <div class="w-full">
          <select
            v-model="healthbookProfile.bloodType"
            class="w-full py-3 px-2 rounded-lg bg-white border border-solid border-gray-400 font-normal"
          >
            <option v-for="(bold, i) in blood" :key="i" :value="bold">
              {{ bold }}
            </option>
          </select>
        </div>
      </div>

      <div class="flex flex-row items-center w-full">
        <label class="mr-2 text-sm font-semibold text-gray-600 w-1/12"
          >Taille:
        </label>
        <div class="w-full relative">
          <input
            type="number"
            min="1"
            v-model="healthbookProfile.height"
            class="bg-white border border-solid border-gray-300 py-3 px-1 rounded-cu w-full"
            placeholder="Taille"
          />
          <span class="placeholder-unit-abs">CM</span>
        </div>
      </div>
      <div class="flex flex-row items-center w-full">
        <label class="mr-2 text-sm font-semibold text-gray-600  w-1/12 "
          >Poids:
        </label>
        <div class="w-full relative">
          <input
            type="number"
            min="1"
            v-model="healthbookProfile.weight"
            class="bg-white border border-solid border-gray-300 py-3 px-1 rounded-cu w-full"
            placeholder="Poids"
          />
          <span class="placeholder-unit-abs">KG</span>
        </div>
      </div>
      <div
        class="flex flex-row items-center w-full content-center"
        style="height:45px;"
      >
        <label class="mr-2 text-sm font-semibold text-gray-600  w-1/12"
          >Fumeur:
        </label>
        <div class="w-full relative flex flex-row content-center">
          <toggle-button
            v-model="healthbookProfileSmoking"
            :labels="{ checked: 'Oui', unchecked: 'Non' }"
            :sync="true"
          ></toggle-button>

          <datepicker
            v-if="healthbookProfile.smoking.isSmoker"
            v-model="healthbookProfile.smoking.since"
            placeholder="Depuis quand ?"
            :language="dateLangFR"
            class="w-full pl-5"
            input-class="bg-white  appearance-none border border-solid inline-block py-3 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full border-gray-300"
          >
          </datepicker>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row items-center w-full content-center lg:w-1/3 mt-3"
      style="height:45px;"
      v-if="patient.gender == 'FEMALE'"
    >
      <label class="mr-2 text-sm font-semibold text-gray-600  w-1/12 "
        >Enceinte:
        {{ healthbookProfile.pregnancy.pregnant }}
      </label>
      <div class="w-full relative flex flex-row content-center">
        <toggle-button
          v-model="healthbookProfilePregnancy"
          :labels="{ checked: 'Oui', unchecked: 'Non' }"
          :sync="true"
        ></toggle-button>
        <div
          class="pl-5 flex flex-row w-full"
          v-if="healthbookProfilePregnancy"
        >
          <datepicker
            v-model="healthbookProfile.pregnancy.probableStartDate"
            placeholder="Depuis quand ?"
            :language="dateLangFR"
            class="w-full pl-5"
            input-class="bg-white  appearance-none border border-solid inline-block py-3 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full border-gray-300"
          >
          </datepicker>
          <datepicker
            v-model="healthbookProfile.pregnancy.expectedDeliveryDate"
            placeholder="Date prévue d'accouchement?"
            :language="dateLangFR"
            class="w-full pl-5"
            input-class="bg-white  appearance-none border border-solid inline-block py-3 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full border-gray-300"
          >
          </datepicker>
        </div>
      </div>
    </div>
    <div class="text-right pt-10 pb-5">
      <button
        class="bg-dokBlue-ultra ml-2 border-0 py-2 px-2 rounded-cu text-white cursor-pointer"
        @click="savePrimaryElement"
      >
        Sauvegarder
      </button>
    </div>
    <div class="grid gap-2 grid-cols-1 xl:grid-cols-1 mt-5">
      <!-- Vaccins -->
      <vaccins :patient="patient"></vaccins>
      <!-- Allergies & intolérances -->
      <allergies-intolerances :patient="patient"></allergies-intolerances>
      <!-- Antécédents Médicaux -->
      <antecedents-medicaux :patient="patient"></antecedents-medicaux>
      <!-- Antécédents Chirurgicaux -->
      <antecedents-chirugicaux :patient="patient"></antecedents-chirugicaux>
      <!-- Antécédents Familiaux -->
      <antecedents-familiaux :patient="patient"></antecedents-familiaux>
      <!-- Médicaments -->
      <medicaments :patient="patient"></medicaments>
      <div class="text-right my-10">
        <button
          @click="resumeMode"
          class="bg-dokBlue-ultra ml-2 border-0 py-2 px-2 rounded-cu text-white cursor-pointer text-xl"
        >
          Terminer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const vaccins = () => import("./vaccins");
const allergiesIntolerances = () => import("./allergiesIntolerances");
const antecedentsMedicaux = () => import("./antecedentsMedicaux");
const antecedentsChirugicaux = () => import("./antecedentsChirugicaux");
const antecedentsFamiliaux = () => import("./antecedentsFamiliaux");
const Medicaments = () => import("./Medicaments");
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  props: {
    patient: {
      type: Object
    },
    editByDoctor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    vaccins,
    allergiesIntolerances,
    antecedentsMedicaux,
    antecedentsChirugicaux,
    antecedentsFamiliaux,
    Medicaments,
    Datepicker
  },
  data() {
    return {
      dateLangFR: fr,
      healthbookProfile: {
        smoking: {},
        pregnancy: {}
      },
      healthbookProfilePregnancy: false,
      healthbookProfileSmoking: false,
      user: {},
      blood: ["AB+", "AB-", "A+", "A-", "B+", "B-", "O+", "O-"]
    };
  },
  watch: {
    healthbookProfileSmoking: function(newVal, oldVal) {
      this.healthbookProfile.smoking.isSmoker = newVal;
    },
    healthbookProfilePregnancy: function(newVal, oldVal) {
      this.healthbookProfile.pregnancy.isPregnant = newVal;
    }
  },
  mounted() {
    const el = this;
    if (this.patient) {
      this.getPatientHealthProfile({
        patientId: this.patient._id,
        onData: function(data) {
          el.healthbookProfile = data.data ? data.data : {};
          if (!el.healthbookProfile.smoking) {
            el.healthbookProfileSmoking = false;
            el.healthbookProfile.smoking = { isSmoker: false };
          } else if (!el.healthbookProfile.smoking.isSmoker) {
            el.healthbookProfileSmoking = false;
            el.healthbookProfile.smoking.isSmoker = false;
          } else {
            el.healthbookProfileSmoking = true;
          }
          if (!el.healthbookProfile.pregnancy) {
            el.healthbookProfilePregnancy = false;
            el.healthbookProfile.pregnancy = { isPregnant: false };
          } else if (!el.healthbookProfile.pregnancy.isPregnant) {
            el.healthbookProfilePregnancy = false;
            el.healthbookProfile.pregnancy.isPregnant = false;
          } else {
            el.healthbookProfilePregnancy = true;
          }
        }
      });
    } else {
      this.user = this.$auth.getUserInfo();
    }
  },
  methods: {
    ...mapActions({
      updateProfile: "user/UPDATE_PROFILE",
      updatePatientHealthProfile: "healthbook/UPDATE_PATIENT_HEALTH_PROFILE",
      getPatientHealthProfile: "healthbook/GET_PATIENT_HEALTH_PROFILE"
    }),
    onMountData({ ok, data }) {
      if (ok) {
        this.user = data;
      }
    },
    resumeMode() {
      this.$emit("resume-mode");
    },
    savePrimaryElement() {
      if (this.patient) {
        this.updatePatientHealthProfile({
          patientId: this.patient._id,
          params: {
            height: this.healthbookProfile.height,
            weight: this.healthbookProfile.weight,
            bloodType: this.healthbookProfile.bloodType,
            smoking: {
              isSmoker: this.healthbookProfile.smoking.isSmoker,
              since:
                this.healthbookProfile.smoking.isSmoker &&
                this.healthbookProfile.smoking.since
                  ? this.customFormatter(this.healthbookProfile.smoking.since)
                  : ""
            },
            pregnancy: {
              isPregnant: this.healthbookProfile.pregnancy.isPregnant,
              probableStartDate:
                this.healthbookProfile.pregnancy.isPregnant &&
                this.healthbookProfile.pregnancy.probableStartDate
                  ? this.customFormatter(
                      this.healthbookProfile.pregnancy.probableStartDate
                    )
                  : "",
              expectedDeliveryDate:
                this.healthbookProfile.pregnancy.isPregnant &&
                this.healthbookProfile.pregnancy.expectedDeliveryDate
                  ? this.customFormatter(
                      this.healthbookProfile.pregnancy.expectedDeliveryDate
                    )
                  : ""
            }
          },
          onData: this.onHealtProfileData
        });
      } else {
        this.updateProfile({ data: this.user, onData: this.onData });
      }
    },
    onData({ ok, message }) {
      this.$vs.notify({
        color: "#4BB543",
        // title: ok ? "Success" : "Error",
        text: message
      });
      if (ok) {
        this.$auth.setUserInfo(this.user);
        this.$vs.loading.close();
      }
      this.$vs.loading.close();
    },
    onHealtProfileData(data) {
      this.$vs.notify({
        time: 4000,
        text: data.message,
        color: data.ok ? "success" : "danger",
        iconPack: "feather",
        icon: data.ok ? "icon-check" : "icon-x"
      });
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
      // return moment(date).format('DD MMM YYYY');
    }
  }
};
</script>

<style lang="scss">
.vue-js-switch {
  width: 52px;
}
.line-category-allergie {
  min-height: 35px;
}
.placeholder-unit-abs {
  position: absolute;
  top: 9px;
  right: 5px;
  color: #aaa;
  z-index: 7;
  background-color: #fff;
  display: block;
  height: 29px;
  padding: 5px;
}
input {
  font-size: 15px;
  color: #626262;
}
.vdp-datepicker input {
  border: 0;
}
.vdp-datepicker__calendar {
  width: 250px !important;
  .cell {
    height: 27px !important;
    line-height: 27px !important;
  }
  .cell.selected {
    background-color: #2c75fc;
    color: #fff;
  }
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border-color: #2c75fc;
}

.vs--searchable .vs__dropdown-toggle {
  height: 34px;
}
.vs__dropdown-toggle .vs__selected-options .vs__selected {
  height: 22px;
  background-color: #2c75fc !important;
  color: #fff;
}
.vs__dropdown-toggle
  .vs__selected-options
  .vs__selected
  .vs__deselect
  svg
  path {
  fill: #fff;
}
td,
th {
  .double-line {
    vertical-align: bottom;
    padding-bottom: 0;
  }
}
.choose-antecent-familiaux,
.antcedent-mediacal-list {
  .vs--searchable {
    .vs__selected-options {
      .vs__search {
        margin-top: 0;
        height: 34px;
      }
    }
    &.has-error {
      // background-color: blue;
      .vs__dropdown-toggle {
        border: 1px solid red;
      }
    }
  }
}
</style>
